const teamModal = {
  teamMemberName: "",
  teamMemberEmail: "",
};

export const eventRegistrationModal = {
  eventId: "",
  eventName: "",
  eventPartcipants: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  universityName: "",
  teamMembers: "",
  isNiitStudent: "",
  teamDetails: [teamModal]
};
